<template>
  <div>
    <defaultFieldTypes
      :fieldAttributes="{
        ...fieldAttributes,
        associatedOption: associatedOption,
      }"
      :field="field"
      :templateContent="result"
      v-on="$listeners"
    :value="value"></defaultFieldTypes>
  </div>
</template>

<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  components: { defaultFieldTypes },
  props: ["fieldAttributes", "field", "result","value"],
  computed: {
    associatedOption() {
      let associatedOption = [];

      if (this.result.valueLine.uplink == 1) {
        associatedOption.push(["uplink", "Uplink"]);
      }
      if (this.result.valueLine.downlink == 1) {
        associatedOption.push(["downlink", "Downlink"]);
      }

      return associatedOption;
    },
  },
};
</script>